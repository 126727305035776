import {
	CircularProgress,
	Dialog,
	DialogContent,
	IconButton,
	Zoom as TZoom
} from "@mui/material"
import React, { Fragment, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Zoom } from "swiper/modules"
import { useTranslation } from "react-i18next"

const ImagePreviewDialog = ({ urls = [], labels = [], open, setOpen }) => {
	const { t } = useTranslation()
	const [zoomInActive, setZoomInActive] = useState(false)
	const [zoomOutActive, setZoomOutActive] = useState(true)
	const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
	const swiperRef = useRef(null)

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				disableEscapeKeyDown={true}
				TransitionComponent={TZoom}
				transitionDuration={500}
				PaperProps={{
					sx: {
						background: "transparent"
					}
				}}
				fullScreen
			>
				<DialogContent
					className="image-preview-content"
					sx={{
						padding: "0 !important",
						margin: "0 !important"
					}}
				>
					<div className="close-btn-wrapper right-7 z-10">
						<IconButton
							variant="swiperDialogCloseButton"
							onClick={() => setOpen(false)}
						>
							<i className="bi bi-x" />
						</IconButton>
					</div>
					<Swiper
						lazy="true"
						zoom={{
							toggle: false
						}}
						navigation={false}
						pagination={false}
						modules={[Zoom]}
						className="w-full h-full"
						speed={500}
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper
						}}
						onSlideChange={(a) => {
							setSliderActiveIndex(a?.activeIndex)
						}}
					>
						{urls &&
							urls.length > 0 &&
							urls.map((itemUrl, index) => (
								<SwiperSlide key={`swiper-item-${index}`}>
									{labels && labels.length > 0 && labels[index] && (
										<div className="absolute top-4 left-4 flex items-center z-10 text-white text-xl p-3 bg-black bg-opacity-30 rounded-lg font-medium">
											{t("common.table.name")}: {labels[index]}
										</div>
									)}
									<div className="swiper-zoom-container">
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}/${itemUrl}`}
											alt="image-preview"
											loading="lazy"
										/>
									</div>
									<div className="swiper-lazy-preloader !animate-none !border-none">
										<CircularProgress size={40} sx={{ color: "white" }} />
									</div>
								</SwiperSlide>
							))}
					</Swiper>
					{urls && urls.length > 1 && (
						<Fragment>
							<div
								className={`swiper-navigation-button-wrapper prev${
									sliderActiveIndex === 0 ? " is-disabled" : ""
								}`}
							>
								<IconButton
									variant="swiperDialogNavigationButton"
									type=""
									color="primary"
									onClick={() => {
										swiperRef.current?.slidePrev()
										setZoomInActive(false)
										setZoomOutActive(true)
									}}
									disabled={sliderActiveIndex === 0}
								>
									<i className="bi bi-chevron-left" />
								</IconButton>
							</div>
							<div
								className={`swiper-navigation-button-wrapper next${
									sliderActiveIndex === urls.length - 1 ? " is-disabled" : ""
								}`}
							>
								<IconButton
									variant="swiperDialogNavigationButton"
									color="primary"
									onClick={() => {
										swiperRef.current?.slideNext()
										setZoomInActive(false)
										setZoomOutActive(true)
									}}
									disabled={sliderActiveIndex === urls.length - 1}
								>
									<i className="bi bi-chevron-right" />
								</IconButton>
							</div>
						</Fragment>
					)}
					<div className="swiper-zoom-buttons-wrapper">
						<div>
							<IconButton
								variant="swiperDialogZoomIcon"
								color="primary"
								onClick={() => {
									setZoomInActive(true)
									setZoomOutActive(false)
									swiperRef.current?.zoom.in()
								}}
								disabled={zoomInActive}
							>
								<i className="bi bi-zoom-in" />
							</IconButton>
							<IconButton
								variant="swiperDialogZoomIcon"
								color="primary"
								onClick={() => {
									setZoomOutActive(true)
									setZoomInActive(false)
									swiperRef.current?.zoom.out()
								}}
								disabled={zoomOutActive}
							>
								<i className="bi bi-zoom-out" />
							</IconButton>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default ImagePreviewDialog
