const formatMultiplies = (numbers = [], fixedLength = 1) => {
	if (numbers.length > 0) {
		numbers = numbers.map((item) =>
			typeof item === "number" ? item.toString() : item
		)
		let withFloatNumber = numbers.reduce(
			(acc, curr) => acc || curr.includes("."),
			false
		)
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? parseInt(
					numbers.reduce((acc, curr) => {
						return (
							((acc * parseFloat(curr).toFixed(fixedLength) * fixedL) /
								fixedL) *
							fixedL
						).toFixed(fixedLength)
					}, 1)
			  ) / Math.pow(fixedL, numbers.length)
			: Math.round(
					parseInt(
						numbers.reduce((acc, curr) => {
							return (
								Math.round((acc * parseFloat(curr) * fixedL) / fixedL) * fixedL
							)
						}, 1)
					) / Math.pow(fixedL, numbers.length)
			  )
	}
	return 0
}

const formatSingle = (number, fixedLength = 1) => {
	if (!isNaN(number)) {
		let currNumber = number.toFixed(fixedLength)
		let withFloatNumber = currNumber.includes(".")
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? parseInt(parseFloat(currNumber) * fixedL) / fixedL
			: Math.round(parseInt(parseFloat(currNumber) * fixedL) / fixedL)
	}
	return 0
}

export { formatMultiplies, formatSingle }
