import FromDateToDateFilter from "./items/FromDateToDateFilter"

const PaymentFiltersComponent = () => {
	return (
		<div className="w-full mt-1 flex items-center">
			<FromDateToDateFilter />
		</div>
	)
}

export default PaymentFiltersComponent
