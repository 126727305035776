import React, { Fragment } from "react"
import { Outlet } from "react-router-dom"
import TopPanelProvider from "./providers/TopPanelProvider"
import NotificationProvider from "./providers/NotificationProvider"
import CurrencyProvider from "./providers/CurrencyProvider"
import useAuth from "hooks/useAuth"

const BaseProvider = () => {
	const [{ user }] = useAuth()
	return (
		<Fragment>
			{user && user.user && (
				<TopPanelProvider>
					<NotificationProvider>
						<CurrencyProvider>
							<Outlet />
						</CurrencyProvider>
					</NotificationProvider>
				</TopPanelProvider>
			)}
		</Fragment>
	)
}

export default BaseProvider
