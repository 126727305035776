import { useEffect, useState } from "react"

export default function useDebounce(value, milliSeconds) {
	const [debouncedValue, setDebouncedValue] = useState("")

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, milliSeconds)

		return () => {
			clearTimeout(handler)
		}
	}, [value])

	return debouncedValue
}
