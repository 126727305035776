import { TextField } from "@mui/material"
import { motion } from "framer-motion"
import { Fragment } from "react"
import { conformToMask } from "react-text-mask"
import formLocalizedHelperText from "utils/formLocalizedHelperText"
import { fadeUp } from "utils/motion"

const FormPassportField = ({
	delay = 0,
	fieldName,
	formik,
	label,
	readOnly = false,
	disabled = false
}) => {
	return (
		<Fragment>
			{formik && formik.values && formik.touched && formik.errors && (
				<TextField
					component={motion.div}
					variants={fadeUp(30, "tween", delay, 0.5)}
					initial="hidden"
					animate="show"
					viewport={{ once: true, amount: 0.25 }}
					color="formColor"
					variant="outlined"
					fullWidth
					id={fieldName}
					name={fieldName}
					label={label}
					error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
					helperText={
						formik.touched[fieldName] &&
						formLocalizedHelperText(formik.errors[fieldName])
					}
					value={formik.values[fieldName]}
					onChange={(event) => {
						formik.setFieldValue(
							fieldName,
							conformToMask(
								event.target.value,
								[
									/[A-Z]/,
									/[A-Z]/,
									" ",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
									/\d/,
									/\d/,
									/\d/
								],
								{ guide: false }
							)?.conformedValue,
							true
						)
					}}
					InputProps={{
						readOnly: readOnly,
						disabled: disabled
					}}
					autoComplete="off"
				/>
			)}
		</Fragment>
	)
}

export default FormPassportField
